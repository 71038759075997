<template>
  <v-data-table
    :headers="headers"
    :items="ArrayTipos"
    sort-by="calories"
     :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Tipos de Mantenimientos</v-toolbar-title>

        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>


        <v-text-field class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>

        <v-dialog v-model="dialog" max-width="600px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>

           
            <v-toolbar dark color="indigo">
              <v-toolbar-title>
                 <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
                 <v-spacer></v-spacer>
                 <v-icon @click="dialog=false" dark>close</v-icon>

            </v-toolbar>

          
         

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.nombre_tipo"  label="Nombre tipo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.descripcion_tipo" label="Descripcion"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5">
                     <v-select prepend-icon="label"
                              v-model="editedItem.grupo"
                              :items="gruposArray"
                              label="Grupo"
                          ></v-select>

                  </v-col>

                  <v-col cols="12" sm="6" md="4" v-show="editedItem.grupo=='Agendado'">
                    <v-text-field v-model="editedItem.cantidad_km"  label="Cantidad km"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-show="editedItem.grupo=='Agendado'">
                    <v-text-field v-model="editedItem.porcentaje_alerta" label="Porcentaje Alerta"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="4" v-show="editedItem.grupo=='Agendado'">
                    <v-text-field v-model="editedItem.porcentaje_critico" label="Porcentaje Critico"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="12" md="8" v-show="editedItem.grupo=='Agendado'">
                    <v-text-field v-model="editedItem.destinatarios" label="Destinatarios Comun"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" v-show="editedItem.grupo=='Agendado'">
                    <v-text-field v-model="editedItem.orden" label="Orden"></v-text-field>
                  </v-col>

                  

                  

                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>

                  <span class="caption" v-show="editedItem.grupo=='Agendado'">* porcentaje critico avisara a responsable de flota primario y secundario</span>


                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" dark @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Deshabilitar tipo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon small @click="deleteItem(item)">delete</v-icon>
    </template>

    <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
     </template>

    <template v-slot:no-data>
      <v-btn  color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      ArrayTipos:[],
      dialog: false,
      search:'',
      dialogDelete: false,
      headers: [
        { text: 'Nombre tipo', value: 'nombre_tipo' },
        { text: 'Descripcion', value: 'descripcion_tipo' },
        { text: 'Grupo', value: 'grupo' },
        { text: 'Cantidad KM', value: 'cantidad_km' },
        { text: '% Alerta', value: 'porcentaje_alerta' },
        { text:'% Critico', value:'porcentaje_critico'},
        { text: 'Orden', value: 'orden' },
       
        { text: 'Destinatario comun', value: 'destinatarios' },
        { text: 'Activo', value: 'activo' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        nombre_tipo: '',
        descripcion_tipo: '',
         grupo:'Agendado',
        cantidad_km: 0,
        porcentaje_alerta: 0,
        destinatarios:'',
        porcentaje_critico:0,
        orden:0,
        activo:1
      },
      defaultItem: {
        nombre_tipo: '',
        descripcion_tipo: '',
         grupo:'Agendado',
        cantidad_km: 0,
        porcentaje_alerta: 0,
        destinatarios:'',
        porcentaje_critico:0,
         orden:0,
        activo:1
      },

      gruposArray:[
         {text:'Agendado', value:'Agendado'},
         {text:'Correctivo', value:'Correctivo'}
      ],
      

    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo tipo' : 'Edicion tipo'
      },

        ...mapState(['usuario', 'token']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },
  

    methods: {

      ...mapMutations(['mostrarLoading','ocultarLoading']),

      initialize () {
        this.ArrayTipos = [];
        this.listar();
      },


      listar(){

                this.ArrayTipos=[];

                this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`tipos/${this.usuario.id_usr_empresa._id}`,
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTipos=response.data;
                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },

      editItem (item) {
        this.editedIndex = this.ArrayTipos.indexOf(item)
        this.editedItem = Object.assign({}, item);

        this.editedItem.grupo=item.grupo;
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayTipos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

      deleteItemConfirm () {
       // this.ArrayTipos.splice(this.editedIndex, 1)
        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});

                   let id=this.editedItem._id;

                    axios.put(`tipos/${id}`,
                      {'activo':0},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                     
                        this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          //editar registro
         // Object.assign(this.ArrayTipos[this.editedIndex], this.editedItem)


          if(((this.editedItem.nombre_tipo!='')&&(this.editedItem.cantidad_km >0)
          &&(this.editedItem.porcentaje_alerta > 0)&&(this.editedItem.destinatarios!='')&&
          (this.editedItem.porcentaje_critico>0)&&( this.editedItem.orden > 0))||(this.editedItem.grupo=='Correctivo')){



             let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`tipos/${id}`,
                      { 
                        'nombre_tipo': this.editedItem.nombre_tipo,
                        'descripcion_tipo': this.editedItem.descripcion_tipo,
                        'grupo':this.editedItem.grupo,
                        'cantidad_km': this.editedItem.cantidad_km,
                        'porcentaje_alerta': this.editedItem.porcentaje_alerta,
                        'destinatarios':this.editedItem.destinatarios,
                        'porcentaje_critico':this.editedItem.porcentaje_critico,
                        'orden':this.editedItem.orden,
                        'activo':this.editedItem.activo
                       },
                      config

                        ).then(response=>{
                      
                         console.log(response);
                         this.close();
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

       }else{
          alert('faltan campos');
        }




        } else {
          //guaradr nuevo registro

          //this.ArrayTipos.push(this.editedItem)



          if(((this.editedItem.nombre_tipo!='')&&(this.editedItem.cantidad_km >0)&&
          (this.editedItem.porcentaje_alerta > 0)&&(this.editedItem.destinatarios!='')
          &&(this.editedItem.porcentaje_critico > 0)&&( this.editedItem.orden > 0))||(this.editedItem.grupo=='Correctivo')){


          


                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.post('nuevo-tipo',
                      { 

                        'nombre_tipo': this.editedItem.nombre_tipo,
                        'descripcion_tipo': this.editedItem.descripcion_tipo,
                        'grupo':this.editedItem.grupo,
                        'cantidad_km': this.editedItem.cantidad_km,
                        'porcentaje_alerta': this.editedItem.porcentaje_alerta,
                        'destinatarios':this.editedItem.destinatarios,
                        'porcentaje_critico':this.editedItem.porcentaje_critico,
                         'orden':this.editedItem.orden,
                        'id_empresa_tipo':this.usuario.id_usr_empresa._id
                       },
                        config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.close()
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

        }else{
          alert('faltan campos');
        }



        
        }
       
      },
    },
  }
</script>