<template mt-5 >

<v-layout mt-5 >


    <v-card>
        <v-card-title> 
           <v-img max-height="270" src="../assets/img/car_service.jpg"></v-img>
      </v-card-title>

      <v-card-text>


           <v-row>
                    <v-col cols="12" sm="6" md="6" class="text-sm-left">
                        <h2 class="mx-2">
                            <v-avatar size="38" tile class=" mx-4 mb-3">
                                <v-img src="../assets/img/labor.png"></v-img>
                            </v-avatar>
                            <span class="indigo--text">Mantenimientos Disatel</span>
                        </h2>
                    </v-col>



                    <v-col cols="12" sm="6" md="6" class="text-sm-right"> 
                                <v-btn link :to="{name:'login'}" color="indigo" dark style="margin-right:10px;"> ingresar
                                          <v-icon>mdi-login</v-icon>
                                </v-btn>
                    </v-col> 
           </v-row>


           <v-tabs fixed-tabs background-color="indigo" dark>
            <v-tab>Inicio</v-tab>
            <v-tab>Contacto</v-tab>

            
        <v-tab-item>
            <v-row mt-5>
    <v-card elevation="2" class="mt-5 mx-1" height="510px">
        <v-img 
           lazy-src="https://picsum.photos/id/11/10/6"
            max-height="500"
            max-width="350"
            src="../assets/img/repair.jpg">
            </v-img>

            <v-divider></v-divider>


        <v-card-text>
              <span>Mantenimientos</span>

             <div  class="text--primary mx-2">
               <li>Agendar mantenimientos por kilometros.</li>
               <li>Multiples tipos de mantenimientos.</li>
               <li>Alertas por mantenimientos proximos.</li>
               <li>Destinatarios por flotas.</li>
               <li>Registro de costo por mantenimiento.</li>
             </div>

         

        </v-card-text>
    </v-card>



     <v-card elevation="2" class="mt-5 mx-1" height="510px">
        <v-img 
           lazy-src="https://picsum.photos/id/11/10/6"
            max-height="500"
            max-width="350"
             src="../assets/img/bateria.jpg">
            </v-img>


            <v-divider></v-divider>
        <v-card-text>
            <span>Baterias</span>
            <div class="text--primary mx-2">
               <li>Registro de cambios de baterias.</li>
               <li>Visor de garantia activa en bateria.</li>
               <li>Registro de costo por cambios de bateria.</li>
               <li>Graficas de costos de baterias por flotas.</li>
             </div>


        </v-card-text>
    </v-card>




     <v-card elevation="2" class="mt-5 mx-1" height="510px">
        <v-img 
           lazy-src="https://picsum.photos/id/11/10/6"
            max-height="500"
            max-width="350"
             src="../assets/img/llantas.jpg">
            </v-img>


            <v-divider></v-divider>
        <v-card-text>
            <span>Llantas</span>
             <div class="text--primary mx-2">
               <li>Registro de cambios de Llantas.</li>
               <li>Visor de garantia activa.</li>
               <li>Registro de costo por cambios de llantas.</li>
               <li>Graficas de costos de llantas por flotas.</li>
             </div>

        </v-card-text>
    </v-card>
      </v-row>
        </v-tab-item>

        <v-tab-item>

      
                 <v-card>

                   <v-card-title> Para obtener mas informacion de nuestras soluciones<br> Contactanos:</v-card-title>
                   <v-card-text>
                     <v-list>


              
                  <v-list-item>
                         <v-list-item-icon><v-icon>phone_iphone</v-icon></v-list-item-icon>
                         <v-list-item-title>Movil: 89666691</v-list-item-title>
                       </v-list-item>

                       <v-list-item>
                         <v-list-item-icon><v-icon>phone</v-icon></v-list-item-icon>
                         <v-list-item-title>Fijo: 22524330</v-list-item-title>
                       </v-list-item>

                        <v-list-item>
                         <v-list-item-icon><v-icon>email</v-icon></v-list-item-icon>
                         <v-list-item-title>Gps.ni@grupodisatel.com</v-list-item-title>
                       </v-list-item>


                       <v-list-item>
                         <v-list-item-icon><v-icon>home</v-icon></v-list-item-icon>
                         <v-list-item-title>Los robles Managua Nicaragua, contiguo al hotel Colon.</v-list-item-title>
                       </v-list-item>

                     </v-list>
                   </v-card-text>
                 </v-card>
        </v-tab-item>

        </v-tabs>




      




     <v-divider class="mt-5"></v-divider>
      <v-footer padless>
        <v-col  class="text-center" cols="12">
          {{ new Date().getFullYear() }} — <strong>Powered by DISATEL NI</strong>
        </v-col>
      </v-footer>

      </v-card-text>
    </v-card>






 

</v-layout>
</template>

<script>
  export default {
    data: () => ({
     
    }),
  }
</script>