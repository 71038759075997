<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Parametros Empresas</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon><v-icon>magnify</v-icon></v-btn>

      <v-btn icon> <v-icon>dots-vertical</v-icon></v-btn>

      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>apartment</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>account_circle</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-3" class="primary--text">
            <v-icon>email</v-icon>
          </v-tab>

         

        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">

      <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
             <Empresas />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
             <Usuarios />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-3'">
        <v-card flat>
          <v-card-text>
            <ServidoresSmtp />
          </v-card-text>
        </v-card>
      </v-tab-item>


     

      
    </v-tabs-items>

  </v-card>
</template>

<script>


import Empresas from './Empresas.vue';
import ServidoresSmtp from './ServidoresSmtp.vue';
import Usuarios from './Usuarios.vue';


  export default {
      components:{
          Empresas, ServidoresSmtp, Usuarios
         },
    data () {
      return {
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
  }
</script>