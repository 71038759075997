<template>
  <v-card>
    <v-card-title>
       <span> Vehiculos</span>
     
       <v-btn dark color="indigo" class="mx-5" @click="ActualizarGrid()"><v-icon>autorenew</v-icon></v-btn>

      <v-text-field class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="Arrayflota"
      :search="search"
    >


    <template v-slot:[`item.nombre`]="{ item }">
      <v-icon color="info" @click="CambiarOdometroActivo(item)">settings</v-icon>
      <span>{{item.nombre}}</span>
     </template>
    
    
    <template v-slot:[`item.icono`]="{ item }">
        <img  :src="CambiarImgUrl(item.icono)" v-bind:alt="item.icono"   style="width: 30px; height: 30px"/>
     </template>


      <template v-slot:[`item.odometro_hw`]="{ item }">
        
        <v-chip v-show="item.tipo_odo_activo=='Hardware'" class="ma-2" color="green" text-color="white">{{item.odometro_hw}}</v-chip>
        <span v-show="item.tipo_odo_activo=='Software'">{{item.odometro_hw}}</span>
        <v-btn v-show="item.observacion!=''" @click="VerDetalle(item)">{{item.observacion}}</v-btn>

     </template>

     <template v-slot:[`item.odometro_sw`]="{ item }">
         <v-chip v-show="item.tipo_odo_activo=='Software'" class="ma-2" color="red" text-color="white">{{item.odometro_sw}}</v-chip>
        
         <span v-show="item.tipo_odo_activo=='Hardware'">{{item.odometro_sw}}</span>
     </template>

                               
                              
</v-data-table>


<v-dialog v-model="dialog" width="370px">
   <v-card>

     <v-toolbar dark color="indigo">
       <v-toolbar-title>Observacion :{{detalle.unidad}}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialog=false">close</v-icon>
     </v-toolbar>

        <v-card-text style="height:150px;" class="text--center">
       
      
         <span class="mt-5">Observacion: {{detalle.observacion}}</span>
    
        </v-card-text>

        <v-card-actions>
         <v-btn color="red" dark @click="EliminarObservacion()" class="mx-2">Eliminar</v-btn>
          <v-btn outlined color="indigo" @click="dialog=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


<v-dialog v-model="dialogOdometro" width="390px">
   <v-card>

     <v-toolbar dark color="indigo">
       <v-toolbar-title>Odometro activo:{{detalle.unidad}}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialogOdometro=false">close</v-icon>
     </v-toolbar>

        <v-card-text style="height:150px;" class="text--center">
       
        <v-row class="mt-5">
      
         
          <v-col cols="12" sm="12" md="12">

            <span class="mt-5">Tipo odometro activo:<br><br></span>



                    <input type="radio" value="Hardware" v-model="detalle.tipo_odo_activo"> Hardware
                    <input type="radio" value="Software" v-model="detalle.tipo_odo_activo"> Software
 
            </v-col>
        </v-row>
    
        </v-card-text>

        <v-card-actions>
         <v-btn color="success" dark @click="EstablecerTipoOdo()" class="mx-2">Guardar</v-btn>
          <v-btn outlined color="indigo" @click="dialogOdometro=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


  </v-card>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data () {
      return {
        Arrayflota:[],
        search: '',
        headers: [
          { text: 'Icono', value: 'icono' },
          { text: 'IdAvl', value: 'id_avl' },
          { text: 'nombre', value: 'nombre' },
          { text: 'placa', value: 'placa' },
          { text: 'Flota', value: 'flota' },
          { text: 'OdometroHw', value: 'odometro_hw' },
          { text: 'OdometroSw', value: 'odometro_sw' },
          { text: 'Actualizacion', value: 'ultimaTx'},

        ],
        dialog:false,
        detalle:{},

        dialogOdometro:false,
      }
    },
    created () {
      this.listar();
     
    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),

         listar(){

                 this.Arrayflota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        me.Arrayflota=response.data;
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

          },
 

          ActualizarGrid(){

             let config={headers:{ token:this.token}};
            let me=this;
              me.mostrarLoading({titulo:'actualizando con AVL'});
            axios.post('ActualizarAVL',{ '_id':this.usuario.id_usr_empresa._id,
                           
                          },
                        config
                      ).then(response=>{
                      
                          console.log(response);
                          me.ocultarLoading();
                          me.listar();

                      }).catch(e=>{ console.log(e) });            
         }, 
      
         CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
         },

         VerDetalle(item){

           if(item.observacion!=''){
              this.detalle={
                '_id':item._id,
                'unidad':item.nombre+' / '+item.placa, 
                'observacion':item.observacion
                };

                this.dialog=true;
           }
          

         },


         EliminarObservacion(){

              let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               let id=this.detalle._id;

                    axios.put(`vehiculo/${id}`,
                      {'observacion':''},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.dialog=false;
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


         },

         CambiarOdometroActivo(item){


          //console.log(item);
         

            this.detalle={
                '_id':item._id,
                'unidad':item.nombre+' / '+item.placa, 
                'tipo_odo_activo':item.tipo_odo_activo
                };

            this.dialogOdometro=true;
         },

         EstablecerTipoOdo(){

           let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               let id=this.detalle._id;

                    axios.put(`vehiculo/${id}`,
                      {'tipo_odo_activo':this.detalle.tipo_odo_activo},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.dialogOdometro=false;
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

         }

    }, computed:{

       ...mapState(['usuario', 'token']),


    }
  }
</script>